import './App.css';
import React, {useEffect, useState} from 'react';
import SignIn from "./pages/Auth/SignIn";
import { init } from "./api/axios"
import JobDetails from './pages/Job/JobDetails';
import Public from "./Routes/Public";
import {UserServices} from "./Services/users";
import ContentView from "./components/ContentView/ContentView";
import Header from "./components/Header/Header";
import CandidateDashboard from "./pages/Dashboard/CandidateDashboard";
import Private from "./Routes/Private";
import constants, {FEATURE_FLAG_LOCAL_STORAGE_KEYS} from "./Utils/Constants";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ViewDocument from "./pages/Documents/ViewDocument";
import Documents from "./pages/Documents/Documents";
import { useLocalStorage } from './hooks/useLocalStorage';

function App() {
    const [, setIsGrievanceFeatureEnabled] = useLocalStorage(FEATURE_FLAG_LOCAL_STORAGE_KEYS.GRIEVANCE_REPORTING, false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isRouteEnable, setIsRouteEnable] = useState(false);

    const sessionUser = localStorage.getItem('emailId');
    if (sessionUser) {
        reportUsername(sessionUser);
    }

    useEffect(() => {

        async function fetchData() {
            await init();

            const idToken = localStorage.getItem('idtoken');
            const accessToken = localStorage.getItem('accesstoken');

            // HACK: Check for 'undefined' string causing many network calls
            if(idToken && accessToken && idToken !== 'undefined' && accessToken !== 'undefined') {
                await UserServices.verifySession()
                .then((data) => {
                    const session = data.data.session;
                    setIsAuthenticated(session)
                })
                .catch((err) => {
                    setIsAuthenticated(false)
                    localStorage.clear();
                })
            }

            await UserServices.getAppConfig().then((res) => {
                setIsGrievanceFeatureEnabled(!!res.data?.FEATURE_FLAG_ENABLE_GRIEVANCE_REPORT);
            });
            
            setIsRouteEnable(true)
        }

        fetchData();
    }, [setIsGrievanceFeatureEnabled]);

    return (
        <>
            {isRouteEnable && <Router>
                <div className="App">
                    {
                        isAuthenticated &&
                        <> <Header/>
                        <ContentView/>
                    </>}
                </div>
                <Switch>
                    <Public path="/" exact component={SignIn} auth={isAuthenticated} header={false}/>
                    <Public path="/sign-in" exact component={SignIn} auth={isAuthenticated} header={false}/>
                    <Private path={`${constants.DASHBOARD.ROUTE}/${constants.DOCUMENTS.NAME.OFFER_LETTER}/:jobId`} exact component={CandidateDashboard} auth={isAuthenticated}/>
                    <Private path={`${constants.DASHBOARD.ROUTE}`} exact component={CandidateDashboard} auth={isAuthenticated}/>
                    <Private path={`${constants.DOCUMENTS.VIEW}`} exact component={ViewDocument} auth={isAuthenticated}/>
                    <Private path={`${constants.DOCUMENTS.ROUTE}/:jobId`} exact component={Documents} auth={isAuthenticated}/>
                    <Public path="/jobDetails/:jobId" exact component={JobDetails} auth={false} header={!isAuthenticated}/>
                </Switch>
            </Router>}
        </>
    );
}

function reportUsername(username) {
    if(username !== null && username !== '') {
        const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
            detail: {
                customerId: username
            }
        });
        document.dispatchEvent(authenticatedUserEvent);
    }
}

export default App;
